<template>
  <div class="merchantContainer">
    <AppTopNav />
    <div class="container">
      <h3 class="containerTitle">商家入驻申请</h3>
        <form class="form" @submit="onAccountFormSubmit">
        <div class="form-item">
          <div class="input">
            <text>商家简称</text>
            <input
              type="text"
              placeholder="请输入商家简称"
              v-model="formData.merchantName"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <text>商家LOGO</text>
            <input
              type="file"
              accept="image/*"
              @change="defaultImg($event, 'logo')"
              placeholder="请上传LOGO"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <text>联系人</text>
            <input
              type="text"
              placeholder="请输入申请联系人"
              v-model="formData.regUser"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <text>手机号码</text>
            <input
              type="number"
              placeholder="请输入手机号"
              v-model="formData.regPhone"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <text>详细地址</text>
            <input
              type="text"
              placeholder="请输入详细地址"
              v-model="formData.regAddress"
            />
          </div>
        </div>
        

        <div class="form-item">
          <div class="input">
            <text>法人</text>
            <input
              type="text"
              placeholder="请输入法人姓名"
              v-model="formData.licenseUser"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <text>法人证件照</text>
            <input
              type="file"
              accept="image/*"
              @change="defaultImg($event, 'idCard')"
              placeholder="请上传证件照"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <text>统一社会信用代码</text>
            <input
              type="text"
              placeholder="请输入社会信用代码"
              v-model="formData.licenseNum"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <text>营业执照</text>
            <input
              type="file"
              accept="image/*"
              @change="defaultImg($event, 'license')"
              placeholder="请上传营业执照"
            />
          </div>
        </div>
        
        <button type="submit" class="recharge-btn" @click="handleRecharge">
          立即提交
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import AppTopNav from '../../components/AppTopNav.vue';
import { ref } from 'vue';

const formData = ref({
  merchantName: '',
  merchantImg: '',
  regUser: '',
  regPhone: '',
  regAddress: '',
  licenseUser: '',
  licenseUserImg: '',
  licenseNum: '',
  licenseImg: ''
})

const defaultImg = (event, type) => {
  console.log('file=>', event.target.files, 'type=>', type);
  const file = event.target.files[0];
  switch(type) {
    case 'logo':
      formData.value.merchantImg = file
      break;
    case 'idCard':
      formData.value.licenseUserImg = file
      break;
    case 'license':
      formData.value.licenseImg = file
      break;
    default:
      break;
  }
}

const onAccountFormSubmit = () => {
  const enums = {
    merchantName: '商户名称',
    merchantImg: '商户LOGO',
    regUser: '联系人',
    regPhone: '手机号',
    regAddress: '注册地址',
    licenseUser: '法人',
    licenseUserImg: '证件照',
    licenseNum: '统一社会信用代码',
    licenseImg: '营业执照'
  }
  const formObj = formData.value

  for (const key in formObj) {
    if (!formObj[key]) {
      alert(enums[key] + '不能为空')
      return
    }
  }
  
  // 手机号正则
  if(!/^1(3|4|5|6|7|8|9)\d{9}$/i.test(formObj.regPhone)) return alert('请输入正确的手机号')

  alert('申请提交成功！工作人员将在三个工作日内与你联系，请注意接听，谢谢！')

}

</script>

<style lang="less" scoped>
.merchantContainer {
  background: #FFF;
}
.container {
  width: 800px;
  min-height: 500px;
  padding-bottom: 50px;
  margin: 30px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: #eee 0 0 10px;
  .containerTitle {
    font-size: 24px;
    font-weight: 600;
    color: #27ba9b;
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .form {
    min-width: 500px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    &-item {
      margin-bottom: 20px;
      .input {
        display: flex;
        align-items: center;
        position: relative;
        height: 36px;
        > i {
          width: 34px;
          height: 34px;
          background: #27ba9b;
          color: #fff;
          position: absolute;
          left: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 18px;
        }
        text {
          display: block;
          width: 180px;
          text-align: right;
          margin-right: 20px;
          caret-color:transparent;
        }
        input {
          padding: 0 10px;
          border: 1px solid #27ba9b;
          height: 36px;
          width: 100%;
          &.error {
            border-color: @priceColor;
          }
          &.active,
          &:focus {
            border-color: @xtxColor;
          }
          :focus-visible {
            outline: 0;
          }
        }
        input[type="file"] {
          border: none;
          padding: 5px 0 0;
          height: 50px;
          line-height: 36px;
        }
        input[type="number"] {
          -moz-appearance: textfield;
          &:focus-visible {
            outline: 0;
          }
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 14px;
          background: #af8c8c;
          color: #666;
          width: 90px;
          height: 34px;
          cursor: pointer;
        }
        .code.disabled {
          cursor: wait;
        }
      }
      > .error {
        position: absolute;
        font-size: 12px;
        line-height: 28px;
        color: @priceColor;
        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    .agree {
      a {
        color: #069;
      }
    }
    .btn {
      display: block;
      width: 100%;
      height: 40px;
      color: #fff;
      text-align: center;
      line-height: 40px;
      background: @xtxColor;
      border: none;
      &.disabled {
        background: rgb(142, 185, 142);
      }
    }
}

.recharge-btn {
  width: 50%;
  margin: 50px auto 0;
  line-height: 35px;
  border: none;
  cursor: pointer;
  background-color: #27ba9b;
  color: #fff;
  caret-color:transparent;
}
}
</style>